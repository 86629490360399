<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M0.167461 23.5662C1.6841 23.3007 12.9913 20.6501 13 0.614008C13 0.605338 13.0103 0.60012 13.0167 0.605936L23.4948 10.0851C23.4979 10.088 23.4989 10.0925 23.4973 10.0964C20.0122 18.5541 13.0598 23.5468 0.175754 23.5917C0.163167 23.5917 0.155062 23.5683 0.167461 23.5662Z"
      fill="#EAE6FD"
    />
  </svg>
</template>

<template>
  <div class="message-with-tail">
    {{ text }}
    <SvgMessageLeftTail class="tail-svg" />
  </div>
</template>

<script>
import SvgMessageLeftTail from "@/components/Svg/SvgMessageLeftTail.vue";

export default {
  components: { SvgMessageLeftTail },

  props: {
    text: String,
  },
};
</script>

<style scoped>
.message-with-tail {
  width: fit-content;
  padding: 10px 16px;
  background-color: #eae6fd;
  border-radius: 20px;
  position: relative;
  font: var(--response-message-font);
}
.tail-svg {
  position: absolute;
  bottom: 0;
  left: -12px;
}
</style>

<template>
  <component
    :is="ApplicationNannyListItemComponent"
    :item="item"
    :response="response"
    :is-wishlistable="isWishlistable"
    :is-wishlisted="isWishlisted"
    :is-geo="isGeo"
    :is-views-amount="isViewsAmount"
    @click="routeToDetails"
    @handle-wishlist-toggler="handleWishlistToggler"
  >
  </component>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useUserWishlistStore } from "@/stores/userWishlist";

import useIsMobile from "@/composables/useIsMobile";

import ApplicationNannyListItemDesktop from "@/components/Application/Nanny/ApplicationNannyListItemDesktop.vue";
import ApplicationNannyListItemMobile from "@/components/Application/Nanny/ApplicationNannyListItemMobile.vue";
import useAuth from "@/composables/useAuth";

import { useRouter } from "vue-router";
import { ROUTE_APPLICATION_NANNY_DETAILS_NAME } from "@/router/constants";

export default {
  props: {
    item: Object,
    isWishlistable: {
      type: Boolean,
      default: true,
    },
    isGeo: {
      type: Boolean,
      default: true,
    },
    isViewsAmount: {
      type: Boolean,
      default: false,
    },
    response: {
      type: Object,
      required: false,
    },
  },

  emits: ["popup"],

  setup(props, { emit }) {
    const { isMobile } = useIsMobile();

    const ApplicationNannyListItemComponent = computed(() => {
      return isMobile.value
        ? ApplicationNannyListItemMobile
        : ApplicationNannyListItemDesktop;
    });

    const applicationId = computed(() => {
      return props.item.id;
    });

    const isWishlisted = computed(() => {
      const userWishlist = useUserWishlistStore();
      return userWishlist.applications.nanny_ids.includes(applicationId.value);
    });

    const { isAuth } = useAuth();

    const handleWishlistToggler = () => {
      if (isAuth.value) {
        const userWishlist = useUserWishlistStore();
        const isWishlisted = userWishlist.applications.nanny_ids.includes(
          applicationId.value
        );
        if (isWishlisted) {
          userWishlist.pullApplicationFromWishlist(applicationId.value);
        } else {
          userWishlist.pushApplicationToWishlist(applicationId.value);
        }
      } else {
        // show CTA popup -- push to registration
        emit("popup", "wishlist");
      }
    };

    const router = useRouter();
    const routeToDetails = () => {
      router.push({
        name: ROUTE_APPLICATION_NANNY_DETAILS_NAME,
        params: { id: applicationId.value },
      });
    };

    return {
      handleWishlistToggler,
      isWishlisted,
      ApplicationNannyListItemComponent,
      routeToDetails,
    };
  },
};
</script>
